<template>
    <CardPrimary title="Pedidos" :addBtn="{type:'button', route:null}" claseBtn="btn btn-outline-primary" buttonText="Nuevo Pedido" iconBtn="fa fa-shopping-cart pr-2" @btnclick="router.push({name: 'AgregarPedidos'})">
        <template v-slot:container>
            <CardSecondary title="Lista de pedidos" classCard="bg-light" classCuerpo="bg-white">
                <template v-slot:cuerpo>
                    <div class="col-12 row">
                        <div class="form-floating mb-3 px-0 col-sm-10 col-10">
                            <input type="text" @keyup.enter="obtenerPedidos(1)" class="form-control" @input="search = $event.target.value" v-model="search"  id="search" placeholder="Buscar...">
                            <label for="search">Buscar por cliente o vendedor</label>
                        </div>
                        <div class="col-1 col-sm-1 mb-3 px-0 pt-2">
                            <button class="btn btn-primary" @click="obtenerPedidos(1)" title="Fecha"><i class="fa fa-search"></i></button>
                        </div>
                        <!-- <div class="d-flex mb-3 px-0 col-sm-6 col-12 align-items-sm-end ml-2">
                            <label class="my-auto mb-sm-2 mr-3">Filtros:</label>
                            <button class="btn btn-default" @click="buscador='Fecha'" title="Fecha"><i class="fa fa-calendar"></i></button>
                            <button class="btn btn-default" @click="buscador='Cliente'" title="cliente"><i class="fa fa-user"></i></button>
                            <button class="btn btn-default" @click="buscador='Vendedor'" title="Vendedor"><i class="fas fa-headset"></i></button>
                        </div> -->
                    </div>
                   <div class="hovereable card-post--1 col-12 shadow-none row border-bottom mb-3 px-sm-0 mx-sm-0" v-for="(pedido, i) in pedidos" :key="i">
                        <div class="col-12 pt-3 mb-3 text-muted p-0" >
                            <div class="row" @click="verPedido(pedido.id)" role="button">
                                <span class="col-12 col-sm-12 pb-2 row text-info pr-0">
                                    <b class="col-sm-11 col-11">{{pedido.cliente_nombre}}</b>
                                </span>                        
                                <span class="col-12 col-sm-3"><small>Cuit:</small> <b>{{pedido.cliente_cuit}}</b></span>
                                <span class="col-12 col-sm-7"><small>Email:</small> <b>{{pedido.cliente_email}}</b></span>                        
                                <span class="col-12 col-sm-2"><small>Total:</small> <b class="text-success">$ {{pedido.total}}</b></span>                        
                                <span class="col-12 col-sm-4 mt-3"><small>Vendedor: <b>{{pedido.usuario.name}}</b></small></span>                        
                                <span class="col-12 col-sm-2 mt-3"><small>Fecha:</small> <b>{{new Date(pedido.created_at).toLocaleDateString("en-GB")}}</b></span>                        

                            </div>                                
                        </div>                            
                    </div>

                    <nav aria-label="Page navigation" style="background-color: white;">
                        <ul class="pagination text-center justify-content-center">
                            <li :class="{ 'disabled': !pagination.prev_page_url }">
                            <a @click.prevent="obtenerPedidos(pagination.current_page - 1)" aria-label="Previous" class="btn btn-primary">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                            </li>
                            
                            <!-- Renderizar enlaces de páginas -->
                            <li v-for="page in pagination.links" :key="page.label" :class="{ 'active': page.active }">
                           
                           
                           <!--  <a v-if="page.label.includes('Previous')" :class="{disabled: !pagination.prev_page_url ? true : false}" class="btn btn-primary  mx-1">
                                &laquo;Anterior 
                            </a>
                            <a v-else-if="page.label.includes('Next')" :class="{disabled: page.active}" class="btn btn-primary mx-1">
                                Siguiente&raquo;   ADEMAS DEBO AGREGAR V-ELSE-IF en el siguiente link
                            </a> -->
                            <a v-if="page.label >= pagination.current_page - 2 && page.label <= pagination.current_page + 2" 
                                :disabled="page.url ? true:false" 
                                :class="{disabled: page.active}" class="btn btn-primary mx-1"
                                @click.prevent="obtenerPedidos(page.label)"
                                >
                                {{page.label}}
                            </a>
                            </li>

                            <li :class="{ 'disabled': !pagination.next_page_url }">
                            <a @click.prevent="obtenerPedidos(pagination.current_page + 1)" aria-label="Next" class="btn btn-primary">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                            </li>
                        </ul>
                    </nav>
                    <div v-if="pedidos.length == 0">
                        <p class="text-center py-3 text-muted">No se encontraron productos...</p>
                    </div>
                </template>
            </CardSecondary>
        </template>
    </CardPrimary>
</template>
<script>
import CardPrimary from 'plantilla-share/src/lib-components/CardPrimary'
import CardSecondary from 'plantilla-share/src/lib-components/CardSecondary'
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import Pedidos from '@/components/pedidos/pedidos'
import '@/assets/fontawesome6/css/all.css'
import '@/assets/fontawesome6/webfonts/fa-solid-900.ttf'
//import Productos from '../productos/productos'

export default {
    name:"ListarPedidos",
    components:{
                CardPrimary,
                CardSecondary
    },
    setup() {
        // require('@/assets/fontawesome6/css/fontawesome.css')
        // require('@/assets/fontawesome6/webfonts/fa-solid-900.ttf')
        const pedidos = ref([]);
        const router = useRouter();
        const buscador = ref('Fecha')
        const search = ref('')
        const page = ref(1)
        const pagination = ref({})
        onMounted(async() => {
            obtenerPedidos(page.value)

            
        })
        const  obtenerPedidos = async (newPage)=>{
            
            let res = await Pedidos.buscarPedidos(search.value, newPage, buscador.value);
            pedidos.value = res.pedidos.data;
            pagination.value = res.pedidos;
        }
        const verPedido = (id)=>{
            router.push({name: 'VerPedido', params: {id:id}});
        }

       /* const pedidosFiltrados = computed(()=>{
            if(search.value){
                switch(buscador.value){
                    case "Fecha":
                        return pedidos.value.filter((e)=>new Date(e.created_at).toLocaleDateString("en-GB").includes(search.value))
                    case "Vendedor":
                        return pedidos.value.filter((e)=>e.usuario.name.toLowerCase().includes(search.value.toLowerCase()))
                    case "Cliente":
                        return pedidos.value.filter((e)=>e.cliente_nombre.toLowerCase().includes(search.value.toLowerCase()))
                    default:
                        return pedidos.value
                }
            }
            return pedidos.value
        }) */

    return {pedidos, verPedido, router, buscador, search, pagination, obtenerPedidos}
    },
}
</script>