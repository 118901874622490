<template>
  <CardPrimary title="Nuevo Pedido">
    <template v-slot:container>
      <CardSecondary
        title="Datos del cliente"
        classCard="bg-light"
        classCuerpo="bg-white"
      >
        <template v-slot:cuerpo>
          <div class="col-12 row">
            <div class="form-floating form-group col-12">
                <SelectSearch
                    ref="selectSearch"
                    url="clientes"
                    palceholder="Ingrese el apellido"
                    campo="full_name"
                    :subCampo="subCampoClienteSearch"
                    @retorno='(cliente.id = $event.id),
                        (cliente.apellido = $event.apellido),
                        (cliente.nombre = $event.nombre),
                        (cliente.cuit = $event.cuit),
                        (cliente.email = $event.email),
                        (cliente.full_name = $event.full_name)'>
                    <label for="floatingInput">Seleccione el cliente</label>
                    <div class="text-danger"  v-if="data.enviado && v$.cliente.full_name.$error">Debe buscar un cliente</div>
                </SelectSearch>
            </div>
            <div class="form-floating form-group col-sm-6 col-12">
                <input type="text" v-model="cliente.cuit" placeholder="cuit" class="form-control"/>
                <label for="floatingInput">CUIT</label>
                <div class="text-danger" v-if="data.enviado && v$.cliente.cuit.$error">Debe ingresar un CUIT</div>
            </div>
            <div class="form-floating form-group col-sm-6 col-12">
                <input type="text" v-model="cliente.email" placeholder="Email" class="form-control"/>
                <label for="floatingInput">Email</label>
                <div class="text-danger" v-if="data.enviado && v$.cliente.email.$error">Debe ingresar un Email</div>
            </div>
          </div>
        </template>
      </CardSecondary>
      <CardSecondary title="Productos del pedido" classCard="bg-light" classCuerpo="bg-white">
        <template v-slot:cuerpo>
			<div class="row px-3">
				<div class="form-floating form-group col-sm-6 col-12">
					<select
						class="form-select "
						id="floatingSelect"
						aria-label="Floating label select example"
						v-model="listaSelected.id"
					>
						<option v-for="(lista, i) in listas" :key="i" :value="lista.id">{{lista.nombre}}</option>
					</select>
					<label for="floatingSelect">Listas de precio</label>
				</div>
				<div class="form-floating form-group col-sm-6 col-12" v-if="listaSelected?.id != null">
					<SelectSearch ref="selectSearch" :url="'listas/productoslista/'+listaSelected.id" palceholder="Seleccione el producto" campo="nombre_precio" @retorno="(producto.id = $event.id), (producto.descripcion = $event.descripcion), (producto.precio = $event.precio)">
						<label for="floatingInput">Seleccione el producto</label>
						<div class="text-danger" v-if="data.productoEnviado && v$.producto.descripcion.$error">Debe buscar un producto</div>
					</SelectSearch>
				</div>
				<div class="col-12 col-md-6" v-else> <p>Seleccione una lista para buscar productos</p></div>
					<div class=" col-12 row" v-if="listaSelected?.id != null">
						<div class="form-floating form-group col-12 col-md-6">
							<input  type="text" v-model="producto.cantidad" placeholder="cantidad" class="form-control" style="color:black;font-weight: 500;" />
							<label for="floatingInput">Cantidad</label>
						<div class="text-danger" v-if="data.productoEnviado && v$.producto.cantidad.$error">Debe ingresar Cantidad</div>
					</div>
					<div class="col-12 col-md-6 text-right mt-4 pb-4">
						<button class="btn btn-outline-info col-12 col-sm-auto" @click="cargarProducto"><i class="fa fa-plus pr-2 my-auto"></i>Agregar</button>
					</div>
				</div>          
			</div>
        </template>
		</CardSecondary>
		<CardSecondary :title="'Detalle del pedido'" classCard="bg-light" classCuerpo="bg-white">
			<template v-slot:cuerpo>
				<div class="row col-12 ms-3 pe-4">
					<div class="hovereable card-post--1 col-12 shadow-none row border-bottom mb-3 mt-1" v-for="(listaProducto, index) in listaProductos" :key="index">
						<div class="col-12 pt-1 mb-1 text-muted">
							<div class="row">
								<div class="col-sm-11 col-10 row">
									<span class="col-12 col-sm-12 pb-0 row text-info pr-0 my-3">
										<b class="col-sm-11 col-10 col-lg-10">{{listaProducto.descripcion}}</b>
									</span>                     
									<span class="col-12 col-lg-3 col-sm-5">Precio: $<b>{{listaProducto.precio}}</b></span>
									<span class="col-12 col-lg-3 col-sm-5">Cantidad: <b>{{listaProducto.cantidad}}</b></span>
									<span class="col-12 col-lg-3 col-sm-5">Total: $<b>{{listaProducto.subTotal}}</b></span>
								</div>
								<span class="col-2 col-sm-1 col-lg-1 text-right align-items-center d-flex justify-content-right"><button @click="quitar(index, listaProducto)" class="btn text-danger"><i class="fa fa-times"></i></button></span>                        
							</div>   
						</div>                            
					</div>
					<div class="row">
						<div class="my-3 col-12" v-if="listaProductos.length > 0">
							<div class="bg-success col-sm-2 col-12 float-right py-2 rounded">
								<h5 class="text-white text-center">Total:  $  {{total}}</h5>
							</div>
						</div>
						<div class="col-12 py-3 text-end">
							<button class="btn btn-outline-primary col-12 col-sm-auto" @click="confirmarPedido" @if="listaProductos.count()>0">Confirmar</button>
						</div>
					</div>
				</div>
			</template>
        </CardSecondary>
    </template>
  </CardPrimary>
</template>
<script>
import CardPrimary from "plantilla-share/src/lib-components/CardPrimary";
import CardSecondary from "plantilla-share/src/lib-components/CardSecondary";
import SelectSearch from "@/components/layouts/SelectSearch";
import { reactive, ref, onMounted, watchEffect } from "vue";
import Lista from '../listas/lista'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import Axios from 'axios';
import Alert from '@/components/utils/alert'
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
    name: "AgregarPedidos",
    components: {
        CardPrimary,
        CardSecondary,
        SelectSearch
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const subCampoClienteSearch = [{nombre:'Cuit:', key:'cuit', clase:'text-primary col-12 col-lg-6 d-flex d-sm-inline'},{nombre:'Email:',key:'email', clase:'text-primary col-12 col-lg-6 d-flex d-sm-inline'}];
        const v$ = useVuelidate();
        let data = reactive({
                    enviado: false,
                    productoEnviado:false,   
                    error: false        
                })
        const cliente = ref({
            id: null,
            nombre: null,
            full_name:null,
            apellido: null,
            email: null,
            cuit: null,
        });
        let producto = reactive({
            id: null,
            descripcion: null,
            precio: null,
            cantidad:1,
            subTotal:null
        });
        const listaProductos = ref([]);
        let total = ref(0);
        
        let listaSelected = reactive({ id: null })
        let listas = ref()
        onMounted( async () => listas.value= await Lista.getListas() )
            
        const cargarProducto = ()=>{
        
            data.productoEnviado =true;
            data.error = false
            v$.value.producto.$touch();
        
            if (v$.value.producto.$invalid)
                return false

            producto.subTotal= producto.cantidad * producto.precio;
        
            if(producto?.id){
                console.log(producto.subTotal)
                total.value += producto.subTotal
                listaProductos.value.push({...producto});
            
                producto.descripcion = null;
                producto.precio = null;
                producto.id = null;
                producto.cantidad=1;
                selectSearch.value.search = null
                data.productoEnviado=false;
            }else {
                return false
            }
        }
        const quitar = (posicion, producto)=>{
            listaProductos.value.splice(posicion,1);
            total.value = total.value - producto.subTotal;
        }

        const selectSearch = ref(null);

        const confirmarPedido = ()=>{
            data.enviado = true;
            data.error = false
            v$.value.cliente.$touch();
            if (v$.value.cliente.$invalid || listaProductos.value.length == 0) {
                return false
            }
            data.enviado = false,
            Axios.post('pedidos', {productos:listaProductos.value, cliente: cliente.value, total:total.value, user:store.state.user.id})
            .then((e)=>{
                if(e.data.success){
                    Alert.Success({text: `Se guardo el pedido con exito.`})
                    .then( () => router.push({name: 'ListarPedidos'}) )
                    localStorage.removeItem('listaProductos')
                    localStorage.removeItem('cliente')
                    localStorage.removeItem('total')
                }else{
                    Alert.Error({text: `Ocurrió un error al intentar guardar`})
                }
            })
            .catch( () => Alert.Error({text: `Ocurrió un error al intentar guardar`}) )
        }

        const getStorageListaProductos = () => {
            if(!localStorage.getItem('listaProductos'))
                return false
            if(JSON.parse(localStorage.getItem('listaProductos')).length > 0 ){
                Alert.Option({
                    icon:"info", 
                    titles:"", 
                    cancelButtonText:"Borrar", 
                    confirmButtonText:"Recuperar", 
                    allowOutsideClick: false,
                    cancelButtonColor:"#d33", 
                    confirmButtonColor:"#198754", 
                    html:"<p>Detectamos que tenes un pedido sin confirmar.</p><p>¿Deseas recuperarlo?</p>"
                })
                .then((e)=>{
                    if(e.isConfirmed){
                        listaProductos.value = JSON.parse(localStorage.getItem('listaProductos'))
                        if(JSON.parse(localStorage.getItem('cliente')))
                            cliente.value = JSON.parse(localStorage.getItem('cliente'))
                        total.value = parseFloat(localStorage.getItem('total'))
                        if(cliente.value)
                            selectSearch.value.search = cliente.value.full_name
                    }else{
                        localStorage.removeItem('listaProductos')
                        localStorage.removeItem('cliente')
                        localStorage.removeItem('total')
                    }
                })
            }

        }
        getStorageListaProductos();

        watchEffect(()=>{
            if(listaProductos.value.length > 0)
                localStorage.setItem('listaProductos', JSON.stringify(listaProductos.value))
            if(cliente.value && cliente.value.id){
                localStorage.setItem('cliente', JSON.stringify(cliente.value))
            }
            if(total.value != 0){
                localStorage.setItem('total', total.value)
            }
        })
        
        return {
            v$,
            data, 
            cliente,        
            selectSearch, 
            producto, 
            listas, 
            listaSelected,
            subCampoClienteSearch,
            cargarProducto,
            quitar,
            listaProductos,
            total,
            confirmarPedido 
        };
    },
    validations(){
        return {
            listaSelected:{
                id: {required}
            },
            producto:{
                descripcion: { required },
                precio: { required },
                cantidad: {required}
            },
            cliente:{
                nombre: { required },
                apellido: { required },
                email: { required },
                cuit: { required },
                full_name: {required}
            }
        }
    }
};
</script>