<template>
<CardPrimary title="Pedido">
        <template v-slot:container>
            <CardSecondary title="Cliente" classCard="bg-light" classCuerpo="bg-white">
                <template v-slot:cuerpo >
                    <div class="col-12 p-0 mb-3">
                        <div class="col-12 row p-0 m-0">
                            <div class=" col-12 mb-2">
                                <span class="text-success"><b> {{pedido.cliente_nombre}}</b></span>
                            </div>
                            <div class=" col-lg-3 col-md-3 col-12">
                                <span><small>Cuit: </small><b class="text-muted"> {{pedido.cliente_cuit}}</b> </span>
                            </div>
                            <div class="  col-lg-6 col-md-6 col-12">
                                <span><small>Email: </small><b class="text-muted"> {{pedido.cliente_email}}</b> </span>
                            </div>
                            <div class=" col-lg-3 col-md-3 col-12">
                                <span><small>Fecha: </small><b class="text-muted">{{new Date(pedido.created_at).toLocaleDateString("en-GB")}}</b>  </span>
                            </div>
                        </div>
                        <div class="col-12 row mt-3 text-right">
                            <div class="col-12">
                                <button class="btn btn-outline-success px-5 me-3" @click="reenviarPedido">Re enviar PDF</button>
                            </div>
                        </div>
                    </div>
                </template>
            </CardSecondary>
            <CardSecondary title="Productos del pedido" classCard="bg-light" classCuerpo="bg-white">
                <template v-slot:cuerpo >
                    <div class="col-12 p-0">
                        <div class="hovereable card-post--1 col-12 shadow-none row border-bottom mb-3" v-for="(listaProducto, i) in pedido.pedido_productos" :key="i">
                            <div class="col-12 pt-1 mb-1 text-muted">
                                <div class="row">
                                  <div class="col-12 row">
                                    <span class="col-12 col-sm-12 pb-0 row text-info pr-0">
                                        <b class="col-sm-12 col-12 col-lg-12">{{listaProducto.producto}}</b>
                                    </span>                     
                                    <span class="col-12 col-lg-3 col-sm-5">Precio: $<b>{{listaProducto.precio}}</b></span>
                                    <span class="col-12 col-lg-3 col-sm-5">Cantidad: <b>{{listaProducto.cantidad}}</b></span>
                                    <span class="col-12 col-lg-3 col-sm-5">Total: $<b>{{listaProducto.precio * listaProducto.cantidad}}</b></span>
                                  </div>
                                                             
                                </div>   
                            </div>                            
                        </div>
                        <div class="row">
                            <div class="my-3 col-12">
                                <div class="bg-success col-sm-auto col-12 float-right py-2 rounded">
                                    <h3 class="text-white">Total:  $  {{pedido.total}}</h3>
                                </div>
                            </div>
                            <div class="col-12 py-3 text-end">
                                <button class="btn btn-outline-primary px-4" @click="descargarPedido">Generar PDF</button>
                            </div>
                        </div>
                    </div>                
                </template>
            </CardSecondary>
        </template>
</CardPrimary>  
</template>
<script>
import CardPrimary from 'plantilla-share/src/lib-components/CardPrimary'
import CardSecondary from 'plantilla-share/src/lib-components/CardSecondary'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Pedidos from './pedidos'
import Alert from '@/components/utils/alert'
import Swal from 'sweetalert2';
import { useStore } from 'vuex';

export default {
    name: 'VerPedidos',
    components:{
        CardPrimary,
        CardSecondary,
    },
    setup(){
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const pedido = ref({
            id:null,
            cliente_nombre:null,
            cliente_cuit:null,
            cliente_email:null,
            total:null,
            created_at: null,
            pedido_productos:{}
        });
        let data = {
            email:'',
            user:store.state.user.id,
            pedido:null
        };
        
        onMounted(async()=>{
            await Pedidos.getPedido(route.params.id).then((e)=>{
               pedido.value =e;
               console.log(pedido.value.pedido_productos)
            }).catch(()=>{
                Alert.Error({text: "No se encontro el producto"}).then(()=>{
                    router.push({name: 'ListarPedidos'})
                })
            })
        });
        const ejecutarReenviar = async (data)=>{
            await Pedidos.reenviarPedido(data).then(res=>{
                        if (res.success) {
                            Swal.fire({
                                title:'Exito',
                                text: 'Correo reenviado con éxito.',
                                icon: 'success'
                            })
                        }
                    }).catch(err=>{
                        console.log(err);
                    });   
        };
        const reenviarPedido = ()=>{
            Swal.fire({
                title:'Reenviar pedido',
                icon: 'question',
                text: 'Ingrese el correo de destino',
                showCancelButton: true,
                html:'<div class="form-floating form-group  col-12">'+ 
                            '<input type="email" value="'+pedido.value.cliente_email+'" placeholder="email" id="email" name="email" class="form-control" >'+
                            '<label  for="floatingInput">Ingrese correo de destino</label>'+   
                        '</div>'
            }).then((result)=>{
                if(result.isConfirmed){
                   
                    data.id = pedido.value.id;
                    data.email = document.getElementById('email').value;
                    ejecutarReenviar(data)
                    

                }
            })
        }
        const descargarPedido= async()=>{
            data.pedido = pedido.value.id;
            await Pedidos.descargarPedido(data).then(res =>{
                const url = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
                link.href = url;
                link.id="download";
                link.setAttribute('download', 'Pedido-'+pedido.value.cliente_nombre+'.pdf');
                document.body.appendChild(link);
                link.click();
                document.getElementById("download").remove();
            })
        }
        return {pedido, reenviarPedido, descargarPedido}
    },
    

}
</script>

